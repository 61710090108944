import React, { useState, useEffect } from "react";
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Form,
    FormGroup,
    Label,
} from "reactstrap";
import axios from "axios";
import "./UserList.css";
import { apiURL } from "api";

function UsersList() {
    const [users, setUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [referrals, setReferrals] = useState([]);
    const [isReferralsModalOpen, setReferralsModalOpen] = useState(false);
    const [isCreateModalOpen, setCreateModalOpen] = useState(false);
    const [isEditModalOpen, setEditModalOpen] = useState(false);  // New state for edit modal
    const [isTasksModalOpen, setTasksModalOpen] = useState(false);
    const [newUser, setNewUser] = useState({
        telegram_id: "",
        first_name: "",
        last_name: "",
        username: "",
        points: 0,
    });
    
    const [selectedUser, setSelectedUser] = useState(null);
    const [tasks, setTasks] = useState({ completedTasks: [], incompleteTasks: [] });

    useEffect(() => {
        axios
            .get(`${apiURL}user/all/users/list`)
            .then((response) => {
                setUsers(response.data.users || []); // Fallback to empty array if users is undefined
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, []);

    const filteredUsers = users.filter((user) => {
        const firstName = user.first_name || "";  // Use empty string if first_name is undefined
        const lastName = user.last_name || "";    // Use empty string if last_name is undefined
        const username = user.username || "";     // Use empty string if username is undefined

        return (
            firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            username.toLowerCase().includes(searchTerm.toLowerCase())
        );
    });

    const toggleReferralsModal = () => setReferralsModalOpen(!isReferralsModalOpen);
    const toggleCreateModal = () => setCreateModalOpen(!isCreateModalOpen);
    const toggleTasksModal = () => setTasksModalOpen(!isTasksModalOpen);
    const toggleEditModal = () => setEditModalOpen(!isEditModalOpen);  // Toggle edit modal

    const handleEditClick = (user) => {
        setSelectedUser(user);
        setNewUser({ ...user });  // Pre-fill the form with the selected user data
        toggleEditModal();
    };

    const handleReferralsClick = (user) => {
        console.log("User Data:", user.ReferralsAsReferrer); // Log user data to check if ReferralsAsReferrer is present
        setSelectedUser(user);
        setReferrals(user.ReferralsAsReferrer);
        toggleReferralsModal();
    };
    
    const handleTasksClick = (user) => {
        setSelectedUser(user);
    
        axios
            .get(`${apiURL}tasks/all/incomplate/tasks/${user.telegram_id}`)
            .then((response) => {
                setTasks(response.data);
                toggleTasksModal();
            })
            .catch((error) => {
                console.error("Error fetching tasks:", error);
            });
    };
    
    const handleCreateUser = () => {
        axios
            .post(`${apiURL}user/create/new`, newUser)
            .then((response) => {
                alert("User created successfully!");
                setUsers([...users, response.data]); // Update the users list with the new user
                toggleCreateModal();
            })
            .catch((error) => {
                console.error("Error creating user:", error);
            });
    };

    const handleUpdateUser = () => {
        const { telegram_id, first_name, last_name, username, points } = newUser;

        axios
            .put(`${apiURL}users/update/${telegram_id}`, {
                first_name,
                last_name,
                username,
                points,
            })
            .then((response) => {
                alert("User updated successfully!");
                const updatedUsers = users.map((user) =>
                    user.telegram_id === telegram_id ? { ...user, ...newUser } : user
                );
                setUsers(updatedUsers);
                toggleEditModal();
            })
            .catch((error) => {
                console.error("Error updating user:", error);
            });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewUser({ ...newUser, [name]: value });
    };
console.log(filteredUsers)
    return (
        <div className="content">
            <Row>
                <Col md="12">
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h4">USERS LIST</CardTitle>
                            <Input
                                type="text"
                                placeholder="Search by name or username..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <Button color="success" onClick={toggleCreateModal}>
                                Create New User
                            </Button>
                        </CardHeader>
                        <Label className="mt-3">TOTAL USER: {filteredUsers.length}</Label>
                        <CardBody>
                            <Table className="tablesorter" responsive>
                                <thead className="text-primary">
                                    <tr>
                                        <th>id</th>
                                        <th>Name</th>
                                        <th>Username</th>
                                        <th>Points</th>
                                        <th>Referrals</th>
                                        <th>Completed Task</th>
                                        <th>Completed Tasks  Count</th>
                                        <th className="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredUsers.map((user, idx) => (
                                        <tr key={user.telegram_id}>
                                            <td>{idx + 1}</td>
                                            <td>{`${user.first_name} ${user.last_name}`}</td>
                                            <td>{user.username}</td>
                                            <td>{user.points}</td>
                                            <td>{user.referrals}</td>
                                            <td>{user.completedTasksCount}</td>
                                            <td>{user.count_task_done}</td>
                                            <td className="text-center">
                                                <Button
                                                    size="sm"
                                                    color="primary"
                                                    onClick={() => handleReferralsClick(user)}
                                                    disabled={user.ReferralsAsReferrer?.length === 0}
                                                >
                                                    View Referrals
                                                </Button>

                                                <Button
                                                    size="sm"
                                                    color="info"
                                                    onClick={() => handleTasksClick(user)}
                                                >
                                                    View Tasks
                                                </Button>

                                                <Button
                                                    size="sm"
                                                    color="warning"
                                                    onClick={() => handleEditClick(user)}  // New Edit button
                                                >
                                                    Edit
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            {/* Modal for Editing User */}
            <Modal isOpen={isEditModalOpen} toggle={toggleEditModal} className="dark-modal">
                <ModalHeader toggle={toggleEditModal}>
                    Edit User: {selectedUser?.first_name} {selectedUser?.last_name}
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label for="first_name">First Name</Label>
                            <Input
                                type="text"
                                name="first_name"
                                id="first_name"
                                value={newUser.first_name}
                                onChange={handleInputChange}
                                placeholder="Enter First Name"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="last_name">Last Name</Label>
                            <Input
                                type="text"
                                name="last_name"
                                id="last_name"
                                value={newUser.last_name}
                                onChange={handleInputChange}
                                placeholder="Enter Last Name"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="username">Username</Label>
                            <Input
                                type="text"
                                name="username"
                                id="username"
                                value={newUser.username}
                                onChange={handleInputChange}
                                placeholder="Enter Username"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="points">Points</Label>
                            <Input
                                type="number"
                                name="points"
                                id="points"
                                value={newUser.points}
                                onChange={handleInputChange}
                                placeholder="Enter Points"
                            />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleUpdateUser}>
                        Save Changes
                    </Button>
                    <Button color="secondary" onClick={toggleEditModal}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>

            {/* Modal for Creating New User */}
            <Modal isOpen={isCreateModalOpen} toggle={toggleCreateModal} className="dark-modal">
                <ModalHeader toggle={toggleCreateModal}>Create New User</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label for="telegram_id">Telegram ID</Label>
                            <Input
                                type="text"
                                name="telegram_id"
                                id="telegram_id"
                                value={newUser.telegram_id}
                                onChange={handleInputChange}
                                placeholder="Enter Telegram ID"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="first_name">First Name</Label>
                            <Input
                                type="text"
                                name="first_name"
                                id="first_name"
                                value={newUser.first_name}
                                onChange={handleInputChange}
                                placeholder="Enter First Name"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="last_name">Last Name</Label>
                            <Input
                                type="text"
                                name="last_name"
                                id="last_name"
                                value={newUser.last_name}
                                onChange={handleInputChange}
                                placeholder="Enter Last Name"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="username">Username</Label>
                            <Input
                                type="text"
                                name="username"
                                id="username"
                                value={newUser.username}
                                onChange={handleInputChange}
                                placeholder="Enter Username"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="points">Points</Label>
                            <Input
                                type="number"
                                name="points"
                                id="points"
                                value={newUser.points}
                                onChange={handleInputChange}
                                placeholder="Enter Points"
                            />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleCreateUser}>
                        Create User
                    </Button>
                    <Button color="secondary" onClick={toggleCreateModal}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
   

            <Modal isOpen={isReferralsModalOpen} toggle={toggleReferralsModal} className="dark-modal">
                <ModalHeader toggle={toggleReferralsModal}>
                    Referrals for {selectedUser?.first_name} {selectedUser?.last_name}
                </ModalHeader>
                <ModalBody>
                    {referrals.length > 0 ? (
                        <Table responsive className="dark-table">
                            <thead>
                                <tr>
                                    <th>Telegram ID</th>
                                    <th>Name</th>
                                    <th>Username</th>
                                    <th>Points</th>
                                    <th>Count Task Done</th>
                                </tr>
                            </thead>
                            <tbody>
                                {referrals.map((referral) => (
                                    <tr key={referral.Referred.telegram_id}>
                                        <td>{referral.Referred.telegram_id}</td>
                                        <td>{`${referral.Referred.first_name} ${referral.Referred.last_name}`}</td>
                                        <td>{referral.Referred.username}</td>
                                        <td>{referral.Referred.points}</td>
                                        <td>{referral.Referred.count_task_done}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <p>No referrals available.</p>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleReferralsModal}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>

                {/* Modal for Viewing Tasks */}
                <Modal isOpen={isTasksModalOpen} toggle={toggleTasksModal} className="dark-modal">
                <ModalHeader toggle={toggleTasksModal}>
                    Tasks for {selectedUser?.first_name} {selectedUser?.last_name}
                </ModalHeader>
                <ModalBody>
                    <h4>Incomplete Tasks</h4>
                    {tasks.incompleteTasks.length > 0 ? (
                        <Table responsive className="dark-table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Reward</th>
                                    <th>Link</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tasks.incompleteTasks.map((task) => (
                                    <tr key={task.id}>
                                        <td>{task.name}</td>
                                        <td>{task.reward}</td>
                                        <td><a href={task.link} target="_blank" rel="noopener noreferrer">{task.link}</a></td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <p>No incomplete tasks available.</p>
                    )}
                    <h4>Completed Tasks</h4>
                    {tasks.completedTasks.length > 0 ? (
                        <Table responsive className="dark-table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Reward</th>
                                    <th>Completion Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tasks.completedTasks.map((task) => (
                                    <tr key={task.id}>
                                        <td>{task.name}</td>
                                        <td>{task.reward}</td>
                                        <td>{new Date(task.userTasks[0].completion_date).toLocaleString()}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <p>No completed tasks available.</p>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleTasksModal}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>

        
        </div>
    );
}

export default UsersList;
