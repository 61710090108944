import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";
import axios from "axios";
import { apiURL } from "api";

function TasksList() {
  const [tasks, setTasks] = useState([]);
  const [modal, setModal] = useState(false);
  const [newTask, setNewTask] = useState({
    name: "",
    reward: "",
    link: "",
    icon: "",
    color: "",
  });
  const [errors, setErrors] = useState({});

  // Fetch tasks from the API
  useEffect(() => {
    axios
      .get(`${apiURL}tasks/all`)
      .then((response) => {
        setTasks(response.data.tasks);
      })
      .catch((error) => {
        console.error("Error fetching tasks:", error);
      });
  }, []);

  // Toggle modal for task creation
  const toggleModal = () => setModal(!modal);

  // Handle input changes for the new task form
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTask({
      ...newTask,
      [name]: value,
    });
  };

  // Validate task inputs
  const validateForm = () => {
    const newErrors = {};

    if (!newTask.name) newErrors.name = "Task name is required";
    if (!newTask.reward) newErrors.reward = "Reward is required";
    if (!newTask.link) newErrors.link = "Link is required";
    if (!newTask.icon) newErrors.icon = "Icon is required";
    if (!newTask.color) newErrors.color = "Color is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Create a new task using the API
  const createTask = () => {
    if (validateForm()) {
      axios
        .post(`${apiURL}tasks/create`, newTask)
        .then((response) => {
          console.log("Task created:", response.data);
          setTasks([...tasks, response.data.task]);
          toggleModal();
          setNewTask({
            name: "",
            reward: "",
            link: "",
            icon: "",
            color: "",
          });
        })
        .catch((error) => {
          console.error("Error creating task:", error);
        });
    }
  };

  // Delete a task by ID
  const deleteTask = (taskId) => {
    axios
      .delete(`${apiURL}tasks/${taskId}`)
      .then(() => {
        // Remove the deleted task from the tasks list
        setTasks(tasks.filter((task) => task.id !== taskId));
      })
      .catch((error) => {
        console.error("Error deleting task:", error);
      });
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Task List</CardTitle>
                <Button color="primary" onClick={toggleModal}>
                  Create New Task
                </Button>
              </CardHeader>
              <CardBody>
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Name</th>
                      <th>Reward</th>
                      <th>Link</th>
                      <th className="text-center">Status</th>
                      <th className="text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tasks.map((task, idx) => (
                      <tr key={task.id}>
                        <td>{idx + 1}</td>
                        <td>{task.name}</td>
                        <td>{task.reward}</td>
                        <td>
                          <a
                            href={task.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {task.link}
                          </a>
                        </td>
                        <td className="text-center">{task.status}</td>
                        <td className="text-center">
                          <Button
                            color="danger"
                            onClick={() => deleteTask(task.id)}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

      {/* Task Creation Modal */}
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Create New Task</ModalHeader>
        <ModalBody>
          <Form style={{ backgroundColor: "black", padding: "10px" }}>
            <FormGroup>
              <Label for="name">Task Name</Label>
              <Input
                type="text"
                name="name"
                id="name"
                value={newTask.name}
                onChange={handleInputChange}
                placeholder="Enter task name"
                invalid={!!errors.name}
              />
              {errors.name && <FormFeedback>{errors.name}</FormFeedback>}
            </FormGroup>
            <FormGroup>
              <Label for="reward">Reward</Label>
              <Input
                type="text"
                name="reward"
                id="reward"
                value={newTask.reward}
                onChange={handleInputChange}
                placeholder="Enter reward (e.g. +500)"
                invalid={!!errors.reward}
              />
              {errors.reward && <FormFeedback>{errors.reward}</FormFeedback>}
            </FormGroup>
            <FormGroup>
              <Label for="link">Link</Label>
              <Input
                type="text"
                name="link"
                id="link"
                value={newTask.link}
                onChange={handleInputChange}
                placeholder="Enter task link"
                invalid={!!errors.link}
              />
              {errors.link && <FormFeedback>{errors.link}</FormFeedback>}
            </FormGroup>
            <FormGroup>
              <Label for="icon">Icon</Label>
              <Input
                type="text"
                name="icon"
                id="icon"
                value={newTask.icon}
                onChange={handleInputChange}
                placeholder="Enter icon name (e.g. TelegramIcon)"
                invalid={!!errors.icon}
              />
              {errors.icon && <FormFeedback>{errors.icon}</FormFeedback>}
            </FormGroup>
            <FormGroup>
              <Label for="color">Color</Label>
              <Input
                type="text"
                name="color"
                id="color"
                value={newTask.color}
                onChange={handleInputChange}
                placeholder="Enter color code (e.g. #0088CC)"
                invalid={!!errors.color}
              />
              {errors.color && <FormFeedback>{errors.color}</FormFeedback>}
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={createTask}>
            Create Task
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default TasksList;
