import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  Input, // Added for the search input
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import axios from "axios";
import "./Leaderboard.css"; // Assuming you have a separate CSS file for custom styles
import { apiURL } from "api";

function LeaderboardList() {
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState(""); // Added for search functionality

  useEffect(() => {
    axios.get(`${apiURL}leaderboard`)
      .then((response) => {
        setLeaderboardData(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching leaderboard data:", error);
      });
  }, []);

  const toggleModal = () => setModalOpen(!isModalOpen);

  const handleViewDetailsClick = (user) => {
    setSelectedUser(user);
    toggleModal();
  };

  // Filter the leaderboard data based on the search term
  const filteredLeaderboard = leaderboardData.filter((entry) => {
    const fullName = `${entry.user.first_name} ${entry.user.last_name}`.toLowerCase();
    const username = entry.user.username.toLowerCase();
    const searchLower = searchTerm.toLowerCase();

    return fullName.includes(searchLower) || username.includes(searchLower);
  });

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Leaderboard</CardTitle>
                <Input
                  type="text"
                  placeholder="Search by name or username..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)} // Search input handler
                />
              </CardHeader>
              <CardBody>
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Rank</th>
                      <th>Name</th>
                      <th>Username</th>
                      <th className="text-center">Points</th>
                      <th className="text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredLeaderboard.map((entry) => (
                      <tr key={entry.user.telegram_id}>
                        <td>{entry.rank}</td>
                        <td>{`${entry.user.first_name} ${entry.user.last_name}`}</td>
                        <td>{entry.user.username}</td>
                        <td className="text-center">{entry.user.points}</td>
                        <td className="text-center">
                          <Button
                            color="primary"
                            onClick={() => handleViewDetailsClick(entry.user)}
                          >
                            View Details
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* Dark Themed Modal */}
        <Modal isOpen={isModalOpen} toggle={toggleModal} className="dark-modal">
          <ModalHeader toggle={toggleModal}>
            Details for {selectedUser?.first_name} {selectedUser?.last_name}
          </ModalHeader>
          <ModalBody>
            {selectedUser ? (
              <div>
                <p style={{color: 'white'}}><strong>Telegram ID:</strong> {selectedUser.telegram_id}</p>
                <p style={{color: 'white'}}><strong>Username:</strong> {selectedUser.username}</p>
                <p style={{color: 'white'}}><strong>Points:</strong> {selectedUser.points}</p>
              </div>
            ) : (
              <p>No user details available.</p>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleModal}>Close</Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default LeaderboardList;
